import React, {useState, useRef, useEffect} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {Link, useHistory} from 'react-router-dom';
import { Form } from '@unform/web';

import BoxSocial from '../../components/Form/BoxSocial';
import CustomButton from '../../components/Form/Button';
import Input from '../../components/Form/Input';

import { register } from '../../services/Auth';

import {Divider, IconButton, InputAdornment} from "@material-ui/core";
import {Container} from "./styles";
import {isMobile} from "react-device-detect";
import logo from "../../assets/img/logo.png";
import Copyright from "../../components/Copyright";
import {VideoContainer} from "../SignIn/styles";
import {useTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@material-ui/icons";

function SignUp(props) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const [isRedirectMobile, setRedirectMobile] = useState(false);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setRedirectMobile(props.location.state && props.location.state.params && props.location.state.params.isRedirectMobile);
  }, []);

  async function handleRegister(data) {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required(t('blank_name')),
        email: Yup.string()
            .email(t('email_invalid'))
            .required(t('blank_email')),
        password: Yup.string().required(t('blank_password'))
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('username', data.email);
      formData.append('password', data.password);
      const response = await register(formData);

      if (response && response.status === 200) {
        if (isRedirectMobile && isMobile) {
          window.ReactNativeWebView.postMessage('{"name": "'+data.email+'", "user_token" : "'+response.data.token+'"}');
          return;
        }

        localStorage.setItem('@name', response.data.name);
        localStorage.setItem('userKey', data.email);
        localStorage.setItem('@user_token', response.data.token);
        toast.success(t('register_success'));
        history.push('/dashboard');
      } else {
        toast.error(t('register_error'));
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(t('register_error'));
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
      <>
        <Container className="container">
          <div className="col-12 d-flex flex-column justify-content-center pb-4 pt-4">
            <Link to={"/"}>
              <img
                  alt={"Logo GBinary"}
                  width={150}
                  src={logo}
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
              />
            </Link>
            <br/>
            <p className={"text-white text-center"}>
              {t('login_title')}
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-5 col-sm-12 col-md-7">
              <div className="card o-hidden border-0 shadow-lg my-3">
                <div className="card-body p-0">
                  {/* <!-- Nested Row within Card Body --> */}
                  <div className="row">
                    <div className="col-12" style={{background: '#7e859d'}}>
                      <div className="pl-sm-5 pr-sm-5 pt-sm-2 px-3 mt-xs-5 pt-lg-4 pb-2">
                        <div className="text-center">
                          <br/>
                          <h1 className="h4 mb-4 font-weight-bold text-white">
                            {t('login_create_account')}
                          </h1>
                        </div>
                        <Form ref={formRef} onSubmit={handleRegister}>
                          <div className="row mb-4 mt-4">
                            <div className="col">
                              <Input
                                  type="text"
                                  name="name"
                                  label={t('login_label_input_user_name')}
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <Input
                                  type="text"
                                  name="email"
                                  label={t('login_label_input_user_email')}
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <Input
                                  type={showPassword ? 'text' : 'password'}
                                  name="password"
                                  label={t('login_label_input_user_password')}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        onMouseUp={handleMouseUpPassword}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityOff sx={{ color: 'white' }}/> : <Visibility sx={{ color: 'white' }}/>}
                                      </IconButton>
                                    </InputAdornment>,
                                  }}
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <CustomButton className="btn btn-primary col-12 text-capitalize font-weight-bold btn-criar" loading={loading} type="submit">
                                {t('login_btn_register')}
                              </CustomButton>
                            </div>
                          </div>
                          <div className="row  mb-3">
                            <div className="col text-white">
                              <Divider>{t('login_label_login_google')}</Divider>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <BoxSocial/>
                          </div>
                          <hr/>
                          <div className="row">
                            <div className="col">
                              <p className="text-center text-white">
                                {t('login_account_exists')}
                                <span className="text-white font-weight-bold pl-1" style={{cursor: 'pointer'}}
                                  onClick={()=> {
                                    window.location = isRedirectMobile === 'true' ? '/?isRedirectMobile=true' : '/login';
                                  }}
                                >
                                  <u>{t('login_account_exists_1')}</u>
                                </span>
                              </p>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <Copyright/>
          </div>
        </Container>
      </>
  );
}

export default SignUp;
